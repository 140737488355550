import gql from "graphql-tag";

const SERVICENOW_ACCOUNTS = gql`
  query(
    $instance: DataSourceInstance
    $pagination: PaginationInput
    $filters: ServiceNowAccountFilterInput
  ) {
    servicenow_accounts(
      instance: $instance
      pagination: $pagination
      filters: $filters
    ) {
      total_count
      result {
        sys_id
        name
      }
    }
  }
`;

export { SERVICENOW_ACCOUNTS };
