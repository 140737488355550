// The "\ufeff" in the first line is to declare the encoding information of the file to handle unicode characters.
// https://en.wikipedia.org/wiki/Byte_order_mark
const ZERO_WIDTH_NO_BREAK_SPACE = "\ufeff";

const UtilService = {
  async ConvertFileToBase64({ path }) {
    return await fetch(path)
      .then(response => response.blob())
      .then(
        blob =>
          new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(blob);
          })
      );
  },
  /**
   * Take a list of objects and convert it to a CSV string.
   * @param  {Object} headers An object with properties representing the headers.
   * @param  {Object[]} items The data that should be exported
   * @param  {String} delimiter Delimiter that needs to be used
   */
  convertToCSVString(headers, items, delimiter) {
    const headerNameList = headers
      ? Object.values(headers)
      : Object.keys(items[0]);
    const headerKeys = headers ? Object.keys(headers) : Object.keys(items[0]);

    let csvString = `${ZERO_WIDTH_NO_BREAK_SPACE}${headerNameList.join(
      delimiter
    )}\n`;
    for (let index = 0; index < items.length; index++) {
      const item = items[index];
      let itemLineString = headerKeys
        .map(key => {
          let itemValue = item[key];
          if (!itemValue && !typeof (itemValue === "boolean")) {
            return null;
          } else if (typeof itemValue === "object") {
            return JSON.stringify([itemValue]);
          } else {
            if (String(itemValue).includes(delimiter)) {
              itemValue = JSON.stringify(itemValue);
            }
            return itemValue;
          }
        })
        .join(delimiter);
      csvString += `${itemLineString}\n`;
    }
    return csvString;
  },
  /**
   * Convert provided data to CSV string and export to a .csv file
   * @param  {Object} headers The headers that should be used. Keys are used to find mapping in items, Value is header value
   * @param  {Object[]} items The data that should be exported
   * @param  {string} fileName Name for the file that needs to be exported, default `export.csv`
   * @param  {String} delimiter Delimiter that needs to be used
   */
  exportCSVFile(headers, items, fileName = "export.csv", delimiter = ";") {
    const csvString = this.convertToCSVString(headers, items, delimiter);
    fileName = fileName.includes(".csv") ? fileName : `${fileName}.csv`;

    const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });
    if (navigator.msSaveBlob) {
      // IE 10+
      navigator.msSaveBlob(blob, fileName);
    } else {
      const link = document.createElement("a");
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", fileName);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  },
  upperCaseFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  },
  jsonPathToValue({ path, object }) {
    return path.split(".").reduce((o, k) => o && o[k], object);
  },
  convertUrlStringToAnchorTags(inputText, outputUrlLabel) {
    //URLs starting with http://, https://, or ftp://
    const replacePattern = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gim;
    let replacedText = inputText.replace(
      replacePattern,
      outputUrlLabel
        ? `<a href="$1" target="_blank">${outputUrlLabel}</a>`
        : '<a href="$1" target="_blank">$1</a>'
    );

    return replacedText;
  }
};

export default UtilService;
