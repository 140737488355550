//#region IMPORTS
import { SET_BREADCRUMB } from "@/store/common/breadcrumbs.module";
import FilterDialog from "./components/FilterDialog";
import CriticalTable from "./components/Table";
import { DATETIME_FORMAT } from "@/core/constants/time";
import dayjs from "dayjs";
import UtilService from "../../../../../core/services/util.service";
import { SERVICENOW_ACCOUNTS } from "./graphql/query";
//#endregion

export const defaultFilters = {
  limit: 50,
  query: `(status: open responders: be-operations acknowledged:false AND NOT (priority:p5))`,
  sorting: ["sla=DESC", "priority=ASC", "acknowledged=ASC"],
  sortBy: ["sla", "priority", "acknowledged"],
  sortDesc: [true, false, false]
};

export default {
  apollo: {
    servicenow_accounts() {
      return {
        query: SERVICENOW_ACCOUNTS,
        variables() {
          return {
            pagination: {
              limit: -1
            },
            filters: {
              customer: { value: true }
            }
          };
        }
      };
    }
  },
  components: {
    FilterDialog,
    CriticalTable
  },
  data() {
    return {
      header: "",
      interval: "",
      isPageFullscreen: false,
      lastUpdatedAt: null,
      style: "",
      totalAlerts: 0
    };
  },
  mounted() {
    const self = this;
    this.interval = setInterval(() => {
      const lastUpdatedAt = dayjs(self.lastUpdatedAt);
      if (lastUpdatedAt !== null) {
        const dateDiff = dayjs().diff(lastUpdatedAt, "second");
        if (dateDiff >= 120) {
          self.style = "color: red !important";
        } else {
          self.style = "color: black !important";
        }
      }
    }, 10000);

    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "BE" },
      { title: "Dashboards" },
      { title: "Criticals" }
    ]);
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  created() {
    let hasQuery = false;
    let storedFilters =
      JSON.parse(localStorage.getItem("criticalFilters")) ?? defaultFilters;
    if (storedFilters || Object.keys(storedFilters).length === 0) {
      storedFilters = { ...defaultFilters };
    }
    this.$nextTick(() => {
      Object.keys(this.$route.query).forEach(queryOption => {
        let value = this.$route.query[queryOption];
        switch (queryOption) {
          case "sorting[]":
            value = Array.isArray(value) ? value : [value];
            storedFilters.sortBy = value.map(s => s.split("=")[0]);
            storedFilters.sortDesc = value.map(s => s.split("=")[1] === "DESC");
            storedFilters.sorting = value;
            hasQuery = true;
            break;
          default:
            storedFilters[queryOption] = value;
            hasQuery = true;
            break;
        }
      });
      this.$refs.filterDialog.filterForm.query = storedFilters.query;
      this.$refs.filterDialog.filterForm.limit = storedFilters.limit;
      this.$refs.filterDialog.filterForm.sorting = storedFilters.sorting;

      this.$refs.criticalTable.limit = storedFilters.limit;
      this.$refs.criticalTable.query = storedFilters.query;
      this.$refs.criticalTable.alertTable.sortBy = storedFilters.sortBy;
      this.$refs.criticalTable.alertTable.sortDesc = storedFilters.sortDesc;
      this.$refs.criticalTable.startLoading();

      if (hasQuery) this.$router.replace("").finally();
      localStorage.setItem("criticalFilters", JSON.stringify(storedFilters));
    });
    this.isPageFullscreen = this.$route.path.includes("screen");
  },
  methods: {
    //#region Helpers
    setHeader(event) {
      this.lastUpdatedAt = event.lastUpdatedAt;
      this.header = `Last updated at: ${event.lastUpdatedAt} | Showing ${event.alertCount} of ${event.alertCountTotal} | Token will expire in ${event.expireAt}`;
      this.style = "color: black !important";
    },
    applyFilters(filters) {
      this.$refs.criticalTable.limit = filters.limit;
      this.$refs.criticalTable.query = filters.query;
      this.$refs.criticalTable.alertTable.sortBy = filters.sortBy;
      this.$refs.criticalTable.alertTable.sortDesc = filters.sortDesc;
      this.$refs.criticalTable.startLoading();
    },
    exportAlertsToCsv() {
      const headers = {
        name: "Asset Name",
        priority: "Alert Priority",
        status: "Alert Status",
        message: "Alert Message",
        sla: "SLA",
        customerName: "Customer Name",
        source: "Alert Source",
        createdAt: "Alert Created At",
        acknowledgedAt: "Alert Acknowledged At",
        closedAt: "Alert Closed At",
        acknowledgedBy: "Alert Acknowledged By",
        closedBy: "Alert Closed By",
        incidentUrl: "Incident URL"
      };
      const keyList = Object.keys(headers);
      const items = [];
      this.$refs.criticalTable.alertTable.alerts.forEach(alert => {
        let exportAlert = {};
        keyList.forEach(key => {
          let value;
          let ackTime = alert.report?.ackTime;
          let closeTime = alert.report?.closeTime;
          switch (key) {
            case "name":
              value = alert.server?.name;
              break;
            case "priority":
              value = alert.priority;
              break;
            case "status":
              value = alert.status;
              break;
            case "message":
              value = alert.message;
              break;
            case "sla":
              value = alert.server?.u_sla;
              break;
            case "customerName":
              value =
                this.servicenow_accounts["result"].find(
                  o => o.sys_id === alert.server?.u_customer
                )?.name ?? "unknown";
              break;
            case "source":
              value = alert.source;
              break;
            case "createdAt":
              value = dayjs(alert.createdAt).format(DATETIME_FORMAT.SECOND);
              break;
            case "acknowledgedAt":
              value = ackTime
                ? dayjs(alert.createdAt)
                    .add(ackTime / 1000, "second")
                    .format(DATETIME_FORMAT.SECOND)
                : "";
              break;
            case "closedAt":
              value = closeTime
                ? dayjs(alert.createdAt)
                    .add(closeTime / 1000, "second")
                    .format(DATETIME_FORMAT.SECOND)
                : "";
              break;
            case "acknowledgedBy":
              value = alert.report?.acknowledgedBy;
              break;
            case "closedBy":
              value = alert.report?.closedBy;
              break;
            case "incidentUrl":
              value = `${process.env.VUE_APP_OPSGENIE_APP_URL}/alert/detail/${alert.id}/details`;
              break;
          }
          exportAlert[key] = value;
        });
        items.push(exportAlert);
      });
      const fileName = `alerts_${this.$refs.criticalTable.query.replaceAll(
        " ",
        "_"
      )}`;
      UtilService.exportCSVFile(headers, items, fileName);
    },
    //#endregion

    //#region Navigation
    navigateFullscreen() {
      if (this.isPageFullscreen)
        this.$router.push({ path: "/be/dashboards/criticals" }).finally();
      else
        this.$router
          .push({ path: "/be/dashboards/screen/criticals" })
          .finally();
    }
    //#endregion
  }
};
