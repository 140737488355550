export const TimeService = {
  convertSecondsToString(seconds) {
    seconds = Number(seconds);
    let day = Math.floor(seconds / (3600 * 24));
    let hour = Math.floor((seconds % (3600 * 24)) / 3600);
    let minute = Math.floor((seconds % 3600) / 60);
    let second = Math.floor(seconds % 60);

    let displayList = [];
    day > 0 ? displayList.push(day + (day === 1 ? " day " : " days ")) : 0;
    hour > 0 ? displayList.push(hour + (hour === 1 ? " hr " : " hrs ")) : 0;
    minute > 0
      ? displayList.push(minute + (minute === 1 ? " min " : " mins "))
      : 0;
    second > 0
      ? displayList.push(second + (second === 1 ? " sec" : " secs"))
      : 0;
    return displayList.join(" ");
  }
};
