import gql from "graphql-tag";

const GET_OPSGENIE_ALERTS = gql`
  query($pagination: PaginationInput, $query: String) {
    alerts(pagination: $pagination, query: $query) {
      total_count
      result {
        acknowledged
        server {
          u_customer
          sys_id
          os
          os_version
          ram
          u_hostname
          u_sla
          name
        }
        alias
        createdAt
        id
        message
        priority
        source
        report {
          closedBy
          acknowledgedBy
          closeTime
          ackTime
        }
        status
      }
    }
  }
`;

const GET_OPSGENIE_ALERT = gql`
  query($alert_id: ID!) {
    alert(alert_id: $alert_id, instance: SNOW_BELGIUM) {
      id
      description
      message
      priority
      source
      details {
        ack_flow_status
        incident_number
        incident_url
        runbook_url
        url
        problem
      }
    }
  }
`;

const START_OPSGENIE_WORKFLOW = gql`
  mutation AcknowledgeAlert($alertInput: AlertInput) {
    opsgenieAlertAcknowledgeFlow(alert: $alertInput) {
      alertId
      incidentNumber
      email
    }
  }
`;

export { GET_OPSGENIE_ALERTS, GET_OPSGENIE_ALERT, START_OPSGENIE_WORKFLOW };
