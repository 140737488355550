<template>
  <v-data-table
    class="critical-table"
    :headers="alertTable.headers"
    multi-sort
    :custom-sort="customSort"
    :loading="$apollo.queries.alerts.loading"
    :items="alertTable.alerts"
    :sort-desc="alertTable.sortDesc"
    :sort-by="alertTable.sortBy"
    disable-pagination
    hide-default-footer
    :item-class="itemBackgroundColor"
  >
    <template v-slot:header.createdAt>
      <template v-if="showDate">
        Created At
      </template>
      <template v-else>
        Open Since / Acked Since
      </template>
    </template>
    <template v-slot:item.createdAt="{ item }">
      <v-icon
        v-if="olderThan(item.createdAt, 6, 'hour') && !item.acknowledged"
        :color="getAlertColor(item)"
        style="margin-right: 4px;"
      >
        mdi-clock-alert
      </v-icon>
      <span @click="showDate = !showDate" style="cursor: pointer;">
        <template v-if="showDate">
          {{ item.createdAt | parseTime }}
        </template>
        <template v-else>
          {{ item | showTimeAckOrOpen }}
        </template>
      </span>
    </template>
    <template v-slot:item.acknowledged="{ item }">
      <v-icon>
        {{ item.acknowledged ? "mdi-check" : "mdi-close" }}
      </v-icon>
    </template>
    <template v-slot:item.source="{ item }">
      {{ item.source | replaceFilter }}
    </template>
    <template v-slot:item.title="{ item }">
      {{ item.title }}
    </template>
    <template v-slot:item.description="{ item }">
      {{ item.description }}
    </template>
    <template v-slot:item.sla="{ item }">
      <v-tooltip
        bottom
        v-if="item.server.u_sla === 'unknown' || item.server.u_sla === ''"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-chip
            v-on="on"
            v-bind="attrs"
            :class="`${item.server.u_sla}`"
            light
            small
          >
            {{ item.server.u_sla | replaceFilter }}
          </v-chip>
        </template>
        <span>The SLA is not set in ServiceNow</span>
      </v-tooltip>
      <v-chip v-else :class="`${item.server.u_sla}`" light small>
        {{ item.server.u_sla | replaceFilter }}
      </v-chip>
    </template>
    <template v-slot:item.actions="{ item }">
      <template v-if="isAuthenticated">
        <v-icon
          v-if="!item.acknowledged"
          @click="
            startOpsgenieWorkflow(item);
            item.acknowledged = true;
          "
          :color="getAlertColor(item)"
        >
          mdi-check-circle-outline
        </v-icon>
        <v-icon v-else> mdi-check-circle-outline </v-icon>
      </template>
      <v-icon @click="openOpsgenieAlert(item)" :color="getAlertColor(item)">
        mdi-bell-alert
      </v-icon>
      <v-icon @click="openZabbixProblem(item)" :color="getAlertColor(item)">
        mdi-alert
      </v-icon>
      <v-icon @click="openZabbixProcedure(item)" :color="getAlertColor(item)">
        mdi-file-alert
      </v-icon>
      <v-icon @click="openCombellIv(item)" :color="getAlertColor(item)">
        mdi-server
      </v-icon>
    </template>
  </v-data-table>
</template>

<script>
import dayjs from "dayjs";
import { TimeService } from "@/core/services/time.service";
import { DATETIME_FORMAT } from "@/core/constants/time";
import NotificationService from "@/core/services/notification.service";
import {
  GET_OPSGENIE_ALERT,
  GET_OPSGENIE_ALERTS,
  START_OPSGENIE_WORKFLOW
} from "@/core/services/graphql/queries/opsgenie/queries";
import { PAGE_REQUEST_DATA } from "@/store/common/page.module";
import { defaultFilters } from "@/view/pages/be/dashboards/criticals/Criticals";

export default {
  name: "CriticalTable",
  components: {},
  data() {
    return {
      timer: null,
      query: defaultFilters.query,
      limit: defaultFilters.limit,
      alertCountTotal: 0,
      alertTable: {
        alerts: [],
        headers: [
          { value: "createdAt", text: "Created At" },
          { value: "acknowledged", text: "Ack", align: " d-none" },
          { value: "priority", text: "Priority" },
          { value: "source", text: "Source" },
          { value: "title", text: "Title", sortable: false },
          { value: "description", text: "Description", sortable: false },
          { value: "sla", text: "SLA" },
          { value: "actions", text: "", sortable: false }
        ],
        sortBy: defaultFilters.sortBy,
        sortDesc: defaultFilters.sortDesc
      },
      showDate: true
    };
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  apollo: {
    alerts() {
      return {
        query: GET_OPSGENIE_ALERTS,
        variables() {
          return {
            pagination: {
              limit: this.limit
            },
            query: this.query
          };
        },
        update(data) {
          this.updateData(data.alerts);
        },
        watchLoading(isLoading) {
          this.$store.dispatch(PAGE_REQUEST_DATA, isLoading);
        },
        error(error) {
          NotificationService.error(error);
        },
        skip: true
      };
    }
  },
  computed: {
    isAuthenticated() {
      return this.$store.getters.isAuthenticated;
    },
    accessTokenPayload() {
      return this.$store.getters.accessTokenPayload;
    },
    currentUserEmail() {
      return this.$store.getters.idTokenPayload?.email;
    }
  },
  filters: {
    showTimeAckOrOpen(item) {
      let time;
      let ackTime = item?.report?.ackTime;
      if (ackTime) {
        time = dayjs()
          .subtract(dayjs(item.createdAt).add(ackTime, "millisecond"))
          .valueOf();
      } else {
        time = dayjs().subtract(dayjs(item.createdAt)).valueOf();
      }
      time = TimeService.convertSecondsToString(time / 1000);
      return time;
    },
    replaceFilter: function (string) {
      let mapObj = {
        ".be.sentia.cloud": " ",
        ".srv.combell-ops.net": " ",
        ".srv.sentia-ops.net": " ",
        ".srv.unitt-ops.net": " ",
        "New Relic": "NR",
        Azure: "AZ",
        Grafana: "GR",
        Zabbix: "ZBX",
        business_critical: "BuCri",
        mission_critical: "MiCri",
        non_critical: "NoCri",
        unknown: "?",
        unmanaged: "UnMan"
      };
      let replaceRegex = RegExp(`${Object.keys(mapObj).join("|")}`);
      let replacedString = "";
      if (string) {
        replacedString = string.replace(replaceRegex, function (matched) {
          return mapObj[matched];
        });
      } else {
        return "?";
      }
      return replacedString;
    },
    parseTime: function (string) {
      return dayjs(string).format(DATETIME_FORMAT.SECOND);
    }
  },
  methods: {
    //#region Custom Sort
    customSort(items, index, isDesc) {
      for (let i = 0; i < index.length; i++) {
        let header = index[i];
        let sortDesc = isDesc[i];

        if (header === "sla") {
          items = this.sortBySLA(items);
          if (!sortDesc) {
            items.reverse();
          }
        } else {
          items = !sortDesc
            ? items.orderBy(x => x[header])
            : items.orderByDescending(x => x[header]);
        }
      }
      return items;
    },
    sortBySLA(alerts) {
      let sortedBySLA = [];
      let slaTypes = [
        "mission_critical",
        "business_critical",
        "non_critical",
        "unmanaged",
        "unknown",
        ""
      ];
      slaTypes.forEach(sla => {
        alerts.forEach(alert => {
          if (alert.server.u_sla === sla) {
            sortedBySLA.push(alert);
          }
        });
      });
      return sortedBySLA;
    },
    //#endregion

    //#region Mixins
    itemBackgroundColor(item) {
      if (!item.acknowledged) {
        return item.priority;
      } else {
        let ackTime = item?.report?.ackTime;
        const FOUR_DAYS_IN_SECONDS = 4 * 24 * 60 * 60;
        let time = dayjs()
          .subtract(dayjs(item.createdAt).add(ackTime, "millisecond"))
          .valueOf();
        if (time / 1000 >= FOUR_DAYS_IN_SECONDS) {
          return "days-overtime-4";
        }
        return "";
      }
    },
    getAlertColor(item) {
      const createdAt = dayjs(item.createdAt);
      if (!item.acknowledged) {
        if (this.olderThan(createdAt, 12, "hour")) {
          return "purple";
        } else if (this.olderThan(createdAt, 6, "hour")) {
          return "#eca8ed";
        }
      }
    },
    //#endregion

    //#region Helpers
    startLoading() {
      this.$apollo.queries.alerts.skip = false;
      this.timer = setInterval(() => {
        this.$apollo.queries.alerts.refetch();
      }, 30000);
    },
    headerLoaded() {
      let event = {
        lastUpdatedAt: dayjs().format(DATETIME_FORMAT.SECOND),
        alertCount: this.alertTable.alerts.length,
        alertCountTotal: this.alertCountTotal,
        expireAt: this.tokenExpireTime()
      };
      this.$emit("loadedHeader", event);
    },
    tokenExpireTime() {
      let validSeconds = this.accessTokenPayload?.exp - dayjs().unix();
      return TimeService.convertSecondsToString(validSeconds);
    },
    olderThan(alertCreatedAtDate, value = 12, unit = "hour") {
      const olderThan = dayjs().subtract(value, unit);
      return dayjs(alertCreatedAtDate).isBefore(olderThan);
    },
    openCombellIv(alert) {
      window.open(
        `${process.env.VUE_APP_IV_COMBELL_URL}/search/index?q=${alert.title}`
      );
    },
    openOpsgenieAlert(alert) {
      window.open(
        `${process.env.VUE_APP_OPSGENIE_APP_URL}/alert/detail/${alert?.id}/details`
      );
    },
    async openZabbixProblem(alert) {
      const alertDetail = await this.fetchAlert(alert.id);
      window.open(alertDetail.details?.problem);
    },
    async openZabbixProcedure(alert) {
      const alertDetail = await this.fetchAlert(alert.id);
      window.open(alertDetail.details?.url);
    },
    async fetchAlert(alert_id) {
      if (alert_id) {
        return await this.$apollo
          .query({
            query: GET_OPSGENIE_ALERT,
            variables: {
              alert_id: alert_id
            }
          })
          .then(response => {
            return response.data.alert;
          });
      } else {
        return false;
      }
    },
    //#endregion

    //#region Start OpsGenie Workflow
    async startOpsgenieWorkflow(item) {
      if (!this.isAuthenticated) {
        NotificationService.error("You are not allowed to ACK an alert");
      }
      let index = this.alertTable.alerts.findIndex(a => a.id === item.id);
      try {
        let alert = await this.fetchAlert(item.id);
        let cmdbCi = item.server.sys_id;
        cmdbCi = cmdbCi === "unknown" ? "" : cmdbCi;
        let company = item.server.u_customer;
        company = company === "unknown" ? "" : company;
        const result = await this.$apollo.mutate({
          mutation: START_OPSGENIE_WORKFLOW,
          variables: {
            alertInput: {
              alertId: alert.id,
              description: alert.description,
              email: this.currentUserEmail,
              message: alert.message,
              priority: alert.priority,
              source: alert.source,
              cmdbCi: cmdbCi,
              company: company,
              details: {
                statusAckFlow: alert.details?.ack_flow_status,
                incidentNumber: alert.details?.incident_number
              }
            }
          },
          client: "beClient"
        });
        const caseNumber =
          result.data?.opsgenieAlertAcknowledgeFlow?.incidentNumber;
        if (caseNumber) {
          NotificationService.success(
            `Alert has been acknowledged and an incident has been created with number: '${result.data.opsgenieAlertAcknowledgeFlow.incidentNumber}'`
          );
          this.alertTable.alerts[index].acknowledged = true;
        } else {
          NotificationService.error(
            "Something went wrong while acknowledging an alert"
          );
        }
      } catch (error) {
        NotificationService.error(error);
        this.alertTable.alerts[index].acknowledged = false;
      }
    },
    //#endregion

    //#region Data manipulation
    updateData(alerts) {
      this.alertCountTotal = alerts.total_count;
      alerts = alerts.result;
      if (alerts) {
        // Split the message
        let sourceRegex = /(\[\w+\])/g;
        alerts.forEach(alert => {
          let splitMessage = alert.message.split(" - ");
          alert.title = splitMessage[0].replace(sourceRegex, "");
          alert.description = alert.message
            .replace(splitMessage[0], "")
            .replace("-", "");
          if (alert.server.u_sla == null) alert.server.u_sla = "UNKNOWN";
        });
        // Filter alerts
        this.alertTable.alerts = alerts;
        this.headerLoaded();
      }
    }
    //#endregion
  }
};
</script>
