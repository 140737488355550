<template>
  <v-dialog
    v-model="showFilterDialog"
    persistent
    max-width="75vw"
    :overlay-opacity="0.99"
    overlay-color="white"
  >
    <template v-slot:activator="{ on }">
      <v-btn icon v-on="on">
        <v-icon> mdi-filter</v-icon>
      </v-btn>
    </template>
    <ValidationObserver ref="observer" v-slot="{ invalid }">
      <v-form>
        <v-card-title>
          <span class="headline">Filters</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" md="12">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Query"
                  rules="required"
                >
                  <v-combobox
                    v-model="filterForm.query"
                    :items="queryOptions"
                    label="Query"
                    :error-messages="errors"
                    hint="Please provide an OpsGenie search query. For examples, click here: <a target='_blank' href='https://wiki.sentia.cloud/display/BEOPS/Opsgenie+search+queries'>OpsGenie Search Queries</a>"
                    persistent-hint
                  >
                    <template v-slot:message="{ message }">
                      <span v-html="message" />
                    </template>
                  </v-combobox>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" sm="6" md="12">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Limit"
                  rules="min_value:-1|is_not:0|max_value:500|required"
                >
                  <v-text-field
                    v-model.number="filterForm.limit"
                    type="number"
                    label="Limit"
                    :error-messages="errors"
                    hint="Please specify the number of results to load. Set to ‘-1’ for unlimited. Please only raise this number if absolutely needed in order to avoid overloading OpsGenie"
                    persistent-hint
                  >
                    <template v-slot:message="{ message }">
                      <span v-html="message" />
                    </template>
                  </v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" sm="6" md="12">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Sorting"
                  rules="required"
                >
                  <v-autocomplete
                    multiple
                    chips
                    deletable-chips
                    v-model="filterForm.sorting"
                    :items="sortingOptions"
                    label="Sorting"
                    :error-messages="errors"
                    hint="The specified sorting is only for the already retrieved alerts, this means updating it here will have no influence on how you retrieve the alerts"
                    persistent-hint
                  >
                    <template v-slot:message="{ message }">
                      <span v-html="message" />
                    </template>
                  </v-autocomplete>
                </ValidationProvider>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-btn
            text
            color="warning darken-1"
            @click="showFilterDialog = false"
          >
            Cancel
          </v-btn>
          <v-spacer />
          <v-btn text color="error darken-1" @click="resetFilters()">
            Reset Filters
          </v-btn>
          <v-btn
            text
            color="blue darken-1"
            :disabled="invalid"
            @click="applyFilters()"
          >
            Apply
          </v-btn>
        </v-card-actions>
      </v-form>
    </ValidationObserver>
  </v-dialog>
</template>

<script>
import {
  extend,
  setInteractionMode,
  ValidationObserver,
  ValidationProvider
} from "vee-validate";
import {
  is_not,
  max_value,
  min_value,
  required
} from "vee-validate/dist/rules";
import { defaultFilters } from "@/view/pages/be/dashboards/criticals/Criticals";

//#region Validation rules
setInteractionMode("eager");

extend("min_value", {
  ...min_value,
  message: "{_field_} must be greater than 0"
});
extend("max_value", {
  ...max_value,
  message: "Maximum {_field_} is 500"
});
extend("required", {
  ...required,
  message: "{_field_} is required"
});
extend("is_not", {
  ...is_not,
  message: "{_field_} can not be 0"
});
//#endregion

export default {
  name: "FilterDialog",
  data() {
    return {
      showFilterDialog: false,
      filterForm: {
        query: defaultFilters.query,
        limit: defaultFilters.limit,
        sorting: defaultFilters.sorting
      },
      sortingOptions: [
        "acknowledged=ASC",
        "acknowledged=DESC",
        "sla=ASC",
        "sla=DESC",
        "createdAt=ASC",
        "createdAt=DESC",
        "priority=ASC",
        "priority=DESC"
      ],
      queryOptions: [
        `(status: open responders: be-operations acknowledged:false AND NOT (priority:p5))`,
        `(status: open responders: be-operations AND NOT (priority:p5))`,
        `(status: open responders: be-operations AND (priority:p1 OR priority:p2))`
      ]
    };
  },
  components: {
    ValidationProvider,
    ValidationObserver
  },
  methods: {
    applyFilters() {
      const sortBy = this.filterForm.sorting.map(s => s.split("=")[0]);
      const sortDesc = this.filterForm.sorting.map(
        s => s.split("=")[1] === "DESC"
      );
      const filters = {
        limit: this.filterForm.limit,
        query: this.filterForm.query,
        sortBy,
        sortDesc
      };
      localStorage.setItem("criticalFilters", JSON.stringify(filters));
      this.showFilterDialog = false;
      this.$emit("applyFilters", filters);
    },
    resetFilters() {
      this.filterForm.sorting = defaultFilters.sorting;
      this.filterForm.limit = defaultFilters.limit;
      this.filterForm.query = defaultFilters.query;
      localStorage.setItem("criticalFilter", JSON.stringify(defaultFilters));
      this.showFilterDialog = false;
      this.$emit("applyFilters", defaultFilters);
    }
  }
};
</script>

<style scoped></style>
